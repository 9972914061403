import { DiscordIcon, FacebookIcon, InstagramIcon, LogoImage, TwitterIcon } from '@styles/imgMapping';

interface ICustomConfig {
  [componentKey: string]: {
    [fieldKey: string]: {
      isShow?: boolean;
      link?: string;
      imgSrc?: string;
      imgHoverSrc?: string;
    };
  };
}

export default {
  footer: {
    logo: {
      link: 'https://www.redreamer.io/',
      imgSrc: LogoImage,
      width: '292px',
      mobileWidth: '292px',
    },
    twitter: {
      isShow: true,
      link: 'https://twitter.com/REDREAMER_Lab',
      imgSrc: TwitterIcon,
    },
    discord: {
      isShow: true,
      link: 'https://discord.com/invite/JHWFFQKCYX',
      imgSrc: DiscordIcon,
    },
    instagram: {
      isShow: true,
      link: 'https://www.facebook.com/redreamer.io',
      imgSrc: InstagramIcon,
    },
    facebook: {
      isShow: true,
      link: 'https://www.facebook.com/redreamer.io',
      imgSrc: FacebookIcon,
    },
  },
} as ICustomConfig;
