import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { BinanceIcon, EthereumIcon, PolygonIcon, ThunderCoreIcon } from '../styles/imgMapping';

// mapping mainnet and testnet to chain alias
export const chains = [
  {
    id: 1,
    name: 'eth',
    shortName: 'ETH',
    rpc: 'https://mainnet.infura.io/v3/895203abafe34efb9af3597f0c105c00',
    metamaskDefaultRPC: 'https://mainnet.infura.io/v3/',
    enabled: false,
    network: 'mainnet',
    display: 'ETH',
    icon: EthereumIcon,
  },
  {
    id: 3,
    name: 'eth',
    shortName: 'ETH',
    rpc: 'https://ropsten.infura.io/v3/d9044d96244b426fb3bbe68c46c848cb',
    metamaskDefaultRPC: 'https://ropsten.infura.io/v3/',
    enabled: false,
    network: 'testnet',
    display: 'Ethereum Testnet - Ropsten',
    icon: EthereumIcon,
  },
  {
    id: 4,
    name: 'eth',
    shortName: 'ETH',
    rpc: 'https://rinkeby.infura.io/v3/d9044d96244b426fb3bbe68c46c848cb',
    metamaskDefaultRPC: 'https://rinkeby.infura.io/v3/',
    enabled: false,
    network: 'testnet',
    display: 'Ethereum Testnet - Rinkeby',
    icon: EthereumIcon,
  },
  {
    id: 5,
    name: 'eth',
    shortName: 'ETH',
    rpc: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    metamaskDefaultRPC: 'https://goerli.infura.io/v3/',
    enabled: false,
    network: 'testnet',
    display: 'Ethereum Testnet - Goerli',
    nativeCurrency: {
      name: 'Goerli ETH',
      symbol: 'gorETH',
      decimals: 18,
    },
    icon: EthereumIcon,
  },
  {
    id: 11155111,
    name: 'eth',
    shortName: 'ETH',
    rpc: 'https://sepolia.infura.io/v3/ebec24a431234429a6f464da2e4f98c5',
    metamaskDefaultRPC: 'https://sepolia.infura.io/v3/',
    enabled: false,
    network: 'testnet',
    display: 'Ethereum Testnet - Sepolia',
    nativeCurrency: {
      name: 'Sepolia ETH',
      symbol: 'SepoliaETH',
      decimals: 18,
    },
    icon: EthereumIcon,
  },
  {
    id: 18,
    name: 'tt',
    shortName: 'TT',
    rpc: 'https://testnet-rpc.thundercore.com',
    enabled: false,
    network: 'testnet',
    display: 'ThunderCore Testnet',
    nativeCurrency: {
      name: 'Testnet TT',
      symbol: 'TST',
      decimals: 18,
    },
    icon: ThunderCoreIcon,
  },
  {
    id: 56,
    name: 'bnb',
    shortName: 'BNB',
    rpc: 'https://bsc-dataseed1.binance.org',
    enabled: false,
    network: 'mainnet',
    display: 'Binance Smart Chain Mainnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    icon: BinanceIcon,
  },
  {
    id: 97,
    name: 'bnb',
    shortName: 'BNB',
    rpc: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    enabled: false,
    network: 'testnet',
    display: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'Testnet BNB',
      symbol: 'tBNB',
      decimals: 18,
    },
    icon: BinanceIcon,
  },
  {
    id: 108,
    name: 'tt',
    shortName: 'TT',
    rpc: 'https://mainnet-rpc.thundercore.com',
    enabled: false,
    network: 'mainnet',
    display: 'ThunderCore Mainnet',
    nativeCurrency: {
      name: 'TT',
      symbol: 'TT',
      decimals: 18,
    },
    icon: ThunderCoreIcon,
  },
  {
    id: 137,
    name: 'polygon',
    shortName: 'Polygon',
    rpc: 'https://polygon-rpc.com',
    enabled: true,
    network: 'mainnet',
    display: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    icon: PolygonIcon,
  },
  {
    id: 80001,
    name: 'polygon',
    shortName: 'Polygon',
    rpc: 'https://polygon-mumbai-bor-rpc.publicnode.com',
    enabled: true,
    network: 'testnet',
    display: 'Polygon Testnet - Mumbai',
    nativeCurrency: {
      name: 'Testnet MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    icon: PolygonIcon,
  },
  {
    id: 1030,
    name: 'Conflux eSpace',
    shortName: 'Conflux',
    rpc: 'https://evm.confluxrpc.com',
    enabled: true,
    network: 'mainnet',
    display: 'Conflux eSpace',
    nativeCurrency: {
      name: 'CFX',
      symbol: 'CFX',
      decimals: 18,
    },
    icon: 'https://confluxnetwork.org/favicon.ico',
  },
  {
    id: 71,
    name: 'Conflux eSpace (Testnet)',
    shortName: 'Conflux',
    rpc: 'https://evmtestnet.confluxrpc.com',
    enabled: true,
    network: 'testnet',
    display: 'Conflux eSpace (Testnet)',
    nativeCurrency: {
      name: 'Testnet CFX',
      symbol: 'CFX',
      decimals: 18,
    },
    icon: 'https://confluxnetwork.org/favicon.ico',
  },
].filter((c) => c.enabled);

export const chainMapping = keyBy(chains, 'id');

export const walletRpcConnectConfig = mapValues(chainMapping, (c) => c.rpc);

export const getSwitchNetworks = () => {
  const isApplicationMainnet = (process.env.REACT_APP_NETWORK || 'TESTNET') === 'MAINNET';
  const availableChains = chains.filter((chain) =>
    isApplicationMainnet ? chain.network === 'mainnet' : chain.network === 'testnet',
  );

  return availableChains.map((chain) => {
    const { display, id, rpc, metamaskDefaultRPC, nativeCurrency } = chain;
    return {
      id,
      url: metamaskDefaultRPC || rpc,
      chainName: display,
      nativeCurrency,
      icon: chain.icon,
    };
  });
};
